$primary-color: #00bacb; // #4caed1
$red-100: #f34f77;
$bg-grey: #f5f6f8;

$briomac-brand-color: #0976d1;
/** refactor **/

$yellow-100: #ecb037;
$yellow-200: #eea039;

$green-100: #00bb2d;
$green-200: #00b92b;

$blue-100: #144eea;
$blue-200: #0047ba;
$blue-800: #171725;

$gray-100: #e6e7f0;

$white: #ffffff;
$black: #000000;

$blue-text: $blue-200;

$black-text: $blue-800;
