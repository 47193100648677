@import "styles/variables";
@import "styles/font";
@import "styles/custom";
@import "styles/print";

:root {
  @media screen and (min-width: 768px) {
    font-size: 12px;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
}
.os-web-main {
  font-family: "Poppins", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

li.ant-menu-item.ant-menu-item-selected:before {
  content: "";
  height: 70%;
  width: 3px;
  background: $primary-color;
  position: absolute;
  left: 0;
  border-radius: 0 3px 3px 0;
}

li.ant-menu-item {
  border-radius: 0 !important;
  margin: 0 !important;
  width: 100% !important;
}

.ant-radio-group {
  padding: 6px;
  border-radius: 19px;
  background: $gray-100;
  font-weight: 500;

  .ant-radio-button-wrapper {
    background: transparent !important;
    border: none !important;
    border-radius: 13px !important;
    padding: 2px 20px !important;
    height: auto !important;
    color: #030303;

    &:before {
      display: none;
    }

    &.ant-radio-button-wrapper-checked {
      background: $white !important;
      color: $blue-text !important;
    }
  }
}

.main-logo {
  height: 50px;
}
.main-menu {
  display: flex;
  grid-gap: 32px;
  color: #ffffff;
  font-size: 1.15rem;
}
.ant-card-head-wrapper {
  height: 60px;
}
.ant-card-head-title {
  font-size: 22px;
  color: #00bacb !important;
}
.quote-terms-info {
  background: $bg-grey;
  padding: 12px;
}

.ant-table-wrapper.quote-item-table .ant-table-thead > tr > th {
  background: $primary-color;
  color: $white;
}
.rounded-panel {
  border-radius: 5px;
  padding: 16px 8px;
  border: 1px solid #edeff1;
  background: $bg-grey;
}
.btn-close {
  color: $red-100;
  border-color: $red-100;
}
.btn-close.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: $white;
  border-color: $red-100;
  background-color: $red-100;
}
.btn-primary {
  color: $primary-color;
  border-color: $primary-color;
}
.btn-primary.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: $white;
  border-color: $primary-color;
  background-color: $primary-color;
}

.add-icon {
  color: $primary-color;
  font-size: 26px;
}

.create-invoice-form {
  .ant-table-wrapper .ant-table-thead > tr > th {
    background-color: $primary-color;
    color: $white;
    border-radius: 0 !important;
  }
}
