@media print {
  body {
    font-size: 12px;
  }
}

@media print {
  .print-invoice-wrapper {
    font-size: 80%;
  }
  @page:first {
    margin-top: 0;
  }

  @page {
    margin-top: 1cm;
    .ant-table-summary {
      display: none;
    }
  }

  @page:last {
    margin-top: 0;
    .ant-table-summary {
      display: block;
    }
  }

  .ant-table-summary {
    display: none;
  }
}
