@import "styles/variables";

.ant-btn-primary,
.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background: $primary-color;
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background: $primary-color;
  border-color: $primary-color;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $primary-color;
}
.ant-tabs .ant-tabs-ink-bar {
  background: $primary-color;
}
.ant-tabs .ant-tabs-tab-btn {
  font-size: 18px;
}
.ant-card {
  border-radius: 32px;
}
.main-menu-item a {
  color: $white;
}
.profile-section-header {
  margin: 0 !important;
}

.print-invoice-wrapper,
.create-invoice-form {
  text-align: right;
  margin: 0 auto;
  background: $white;
  padding: 24px 32px;
  .invoice-title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h1 {
      margin-bottom: 0;
      font-size: 46px;
    }
    h4 {
      font-size: 24px;
      color: $briomac-brand-color;
      margin-top: 0;
    }
  }
  .company-info {
    text-align: left;
  }
  .client-logo {
    width: 150px;
  }
  .address-line-item {
    margin: 0;
  }

  .balance-due-wrapper {
    margin-top: 32px;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
    }
  }
  .total-amount {
    color: $briomac-brand-color;
    font-size: 32px;
  }
  .row-section {
    margin: 32px;
    border-top: 1px solid $bg-grey;
  }

  .bill-to {
    text-align: left;
    .section-header {
      color: $briomac-brand-color;
      margin: 12px 0;
    }
  }
  .invoice-info {
    margin-top: 1rem;
  }
  .invoice-info-title,
  .invoice-info-text {
    margin: 0.5rem 0 0.5rem 0;
  }

  .address-line-item,
  .invoice-info-title,
  .invoice-info-text,
  .invoice-terms-info > *,
  .quote-summary-item > *,
  .ant-table-wrapper.print-invoice-table .ant-table-thead > tr > th,
  .ant-table-wrapper.print-invoice-table .ant-table-tbody > tr > td,
  .ant-table-wrapper.print-invoice-table .ant-table-summary > tr > td {
    font-size: 18px;
    line-height: 1.45;
  }

  .quote-summary-item {
    h5 {
      font-size: 18px;
      line-height: 2.5;
      margin: 0;
    }
  }
  .quote-summary-grand-total {
    h3 {
      margin: 0;
      font-size: 24px;
      line-height: 2;
      color: $briomac-brand-color;
    }
  }

  .ant-table-wrapper.print-invoice-table .ant-table-summary > tr > td {
    background-color: $bg-grey;
    font-weight: 500;
  }

  .ant-table-wrapper.print-invoice-table .ant-table-thead > tr > th {
    background: $briomac-brand-color;
    color: $white;
    border-radius: 0 !important;
    line-height: 1.8;
  }
  .invoice-terms-info {
    text-align: left;
    padding: 24px 24px 24px 0;
    h5 {
      margin-bottom: 12px;
    }
  }
}

@media print {
  .print-invoice-wrapper {
    padding: 64px 24px;

    .address-line-item,
    .invoice-info-title,
    .invoice-info-text,
    .invoice-terms-info > *,
    .quote-summary-item > *,
    .ant-table-wrapper.print-invoice-table .ant-table-thead > tr > th,
    .ant-table-wrapper.print-invoice-table .ant-table-tbody > tr > td,
    .ant-table-wrapper.print-invoice-table .ant-table-summary > tr > td {
      font-size: 12px;
      line-height: 1.45;
    }

    .quote-summary-item {
      h5 {
        font-size: 12px;
      }
    }
    .quote-summary-grand-total {
      h3 {
        font-size: 16px;
      }
    }
    .invoice-title {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h1 {
        font-size: 36px;
      }
      h4 {
        font-size: 18px;
      }
    }
    .balance-due-wrapper {
      .total-amount {
        font-size: 24px;
      }
    }
  }
}

.m-0 {
  margin: 0;
}

.customer-type .ant-radio-wrapper {
  padding-left: 16px;
}
.create-customer-form {
  h4 {
    margin-top: 0;
  }
}
.ant-drawer-title {
  h3 {
    margin: 0;
    padding: 8px;
  }
}

.ant-table-wrapper .ant-table,
.ant-typography {
  font-size: 16px;
}
.ant-form-item .ant-form-item-label > label {
  font-size: 14px;
}
